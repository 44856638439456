import { AssistantOutlined, Check, Close } from '@mui/icons-material';
import { Button, Dialog, Fab, Grid, Paper, Popper, TextField, ToggleButton, Typography } from '@mui/material';
import { useState } from 'react';

const FeedbackModal = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [receiveCopy, setReceiveCopy] = useState<boolean>(false);
    const [popperOpen, setPopperOpen] = useState<boolean>(false);
    const [popperContent, setPopperContent] = useState<string>('');
    const [popperAnchor, setPopperAnchor] = useState<null | HTMLElement>(null);
    const [feedback, setFeedback] = useState<string>('');

    const submitFeedback = async () => {
        try {
            const feedbackResponse: Response = await fetch('/feedback/submit', {
                method: 'POST',
                body: JSON.stringify({ feedback, receiveCopy, submissionUrl: document.URL })
            });

            const responseJson = await feedbackResponse.json();

            if (feedbackResponse.ok) {
                return { succeeded: true, message: 'Your feedback has been submitted!' };
            } else {
                return { succeeded: false, message: responseJson.body['message'] };
            }
        } catch (error) {
            return {
                succeeded: false,
                message:
                    'Something went wrong, please try again. If this continues to happen please email us directly at: management@cpeonlineacademy.com'
            };
        }
    };

    return (
        <>
            <Fab
                onClick={() => {
                    setOpen(!open);
                }}
                sx={{ position: 'fixed', bottom: 10, right: 10, zIndex: 2701 }}
                color="info"
            >
                <AssistantOutlined></AssistantOutlined>
            </Fab>
            <Dialog keepMounted open={open}>
                <Grid container spacing={2} padding={'1.5rem'} justifyContent={'flex-end'}>
                    <Grid item xs={12} paddingBottom={'1rem'} marginTop={'1.5rem'}>
                        <Typography variant="body2">
                            Have some feedback or need any help? Please use the box below to send us a message. We will
                            automatically send the current URL and the email address associated with your account along
                            with the email to our team to review in case it is a page specific help request.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingBottom={'1rem'}>
                        <TextField
                            className="feedback-input"
                            type="textbox"
                            multiline
                            fullWidth
                            autoFocus={open}
                            onChange={(event) => {
                                setFeedback(event.target.value);
                            }}
                            value={feedback}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <ToggleButton
                            value={receiveCopy}
                            selected={receiveCopy}
                            onChange={() => {
                                setReceiveCopy(!receiveCopy);
                            }}
                            size="small"
                            color="primary"
                            sx={
                                !receiveCopy
                                    ? { backgroundColor: 'transparent' }
                                    : { backgroundColor: '#0288d1 !important' }
                            }
                        >
                            <Check />
                        </ToggleButton>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Typography variant="body2" paddingTop={'2%'}>
                            I want to receive a copy of my feedback
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Popper
                            className="popper"
                            open={popperOpen}
                            anchorEl={popperAnchor}
                            placement="bottom"
                            sx={{ sm: { width: '80%' }, md: { width: '50%' }, zIndex: 2701 }}
                        >
                            <Paper sx={{ padding: '20px', border: 'lightgray' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} paddingY={'20px'} paddingX={'20px'}>
                                        <Typography variant="body2">{popperContent}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            onClick={() => {
                                                setPopperOpen(false);
                                            }}
                                        >
                                            <Close />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Popper>
                        <Button
                            onClick={async (event) => {
                                setPopperAnchor(event.currentTarget);
                                if (feedback.length == 0) {
                                    setPopperContent(
                                        `It appears you haven't provided any feedback, please type your feedback then try submitting again.`
                                    );
                                    setPopperOpen(true);
                                    setTimeout(() => {
                                        setPopperOpen(false);
                                    }, 1500);
                                    return;
                                }
                                const feedbackResponse = await submitFeedback();
                                setPopperOpen(true);
                                setPopperContent(feedbackResponse.message);
                                setTimeout(() => {
                                    if (feedbackResponse.succeeded) {
                                        setPopperContent('');
                                        setPopperOpen(false);
                                        setFeedback('');
                                        setReceiveCopy(false);
                                        setOpen(!open);
                                    }
                                }, 1500);
                            }}
                            variant="contained"
                            color="info"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export default FeedbackModal;
