import Box from '@mui/material/Box';
import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ApplicationContext from './ContextProvider';
import Classroom from './routes/Classroom';
import Home from './routes/Home';
import Login from './routes/Login';
import PasswordReset from './routes/PasswordReset';
import UserCreation from './routes/UserCreation';
import Files from './routes/Files';
import Links from './routes/Links';
import ForgotPassword from './routes/ForgotPassword';
import ReferralCodeDetails from './routes/ReferralCodeDetails';
import UserManagement from './routes/UserManagement';
import FeedbackModal from './components/FeedbackModal';

const Main = (): JSX.Element => {
    const context = useContext(ApplicationContext);
    const xsSidebarLeftMargin = context.sidebarOpen ? '240px' : '55px';

    return (
        <Box
            sx={{
                transition: { marginLeft: 'step-end' },
                marginLeft: { xs: 0, sm: xsSidebarLeftMargin },
                marginTop: { sm: '40px', md: '40px' },
                overflow: 'hidden'
            }}
        >
            <Routes>
                {context.verifiedLogin && context.permissionLevel === 'Admin' && (
                    <>
                        <Route path="/user_creation" element={<UserCreation />} />
                        <Route path="/user_management" element={<UserManagement />} />
                    </>
                )}
                {context.verifiedLogin && (
                    <>
                        <Route path="/" element={<Navigate to="/classroom" replace />} />

                        {/* Section Display */}
                        <Route path="/classroom" element={<Classroom />}>
                            <Route path=":section_id/" element={<Classroom />}>
                                <Route path=":subsection_id/*" element={<Classroom />}></Route>
                            </Route>
                        </Route>
                        {/* End Section Display */}
                        {/* Content Display */}
                        <Route path="/classroom/content/:content_section_id" element={<Classroom />}>
                            <Route
                                path="/classroom/content/:content_section_id/:content_title"
                                element={<Classroom />}
                            />
                        </Route>
                        {/* End Content Display */}
                        <Route path="/files" element={<Files />}>
                            <Route path=":section_name" element={<Files />} />
                        </Route>
                        <Route path="/links" element={<Links />} />
                    </>
                )}
                {!context.verifiedLogin && (
                    <>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/password_reset" element={<PasswordReset />} />
                        <Route path="/forgot_password" element={<ForgotPassword />} />
                    </>
                )}
                <Route path="/referral_details" element={<ReferralCodeDetails />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            {context.verifiedLogin && <FeedbackModal></FeedbackModal>}
        </Box>
    );
};

export default Main;
